/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeLogPagedResponse } from '../models/ChangeLogPagedResponse';
import type { ChangeTrackerFilter } from '../models/ChangeTrackerFilter';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ChangeTrackerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ChangeLogPagedResponse Success
     * @throws ApiError
     */
    public find({
        requestBody,
    }: {
        requestBody?: ChangeTrackerFilter,
    }): CancelablePromise<ChangeLogPagedResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ChangeTracker/find',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
