/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InMemoryOptions } from '../models/InMemoryOptions';
import type { UserRoleDto } from '../models/UserRoleDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SettingsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns InMemoryOptions Success
     * @throws ApiError
     */
    public getInMemoryOptions(): CancelablePromise<InMemoryOptions> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/settings/get',
        });
    }

    /**
     * @returns InMemoryOptions Success
     * @throws ApiError
     */
    public setInMemoryOptions({
        requestBody,
    }: {
        requestBody: InMemoryOptions,
    }): CancelablePromise<InMemoryOptions> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/settings/set',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserRoleDto Success
     * @throws ApiError
     */
    public getRolesAndGrants(): CancelablePromise<Array<UserRoleDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Settings/get-roles',
        });
    }

    /**
     * @returns UserRoleDto Success
     * @throws ApiError
     */
    public saveRole({
        requestBody,
    }: {
        requestBody?: UserRoleDto,
    }): CancelablePromise<UserRoleDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Settings/save-role',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public deleteRole({
        id,
    }: {
        id: string,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Settings/delete-role/{id}',
            path: {
                'id': id,
            },
        });
    }

}
