/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmailFilter } from '../models/EmailFilter';
import type { EmailMessageDtoPagedResponse } from '../models/EmailMessageDtoPagedResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EmailService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns EmailMessageDtoPagedResponse Success
     * @throws ApiError
     */
    public find({
        requestBody,
    }: {
        requestBody?: EmailFilter,
    }): CancelablePromise<EmailMessageDtoPagedResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Email/find',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
