/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EnvironmentType {
    LOCAL = 'Local',
    DEV = 'Dev',
    TEST = 'Test',
    PROD = 'Prod',
}
