/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TextQuestion } from '../models/TextQuestion';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class HelloWorldService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns TextQuestion Success
     * @throws ApiError
     */
    public getApiHelloWorldHelloworld(): CancelablePromise<TextQuestion> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/HelloWorld/helloworld',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public getApiHelloWorldFoo({
        name,
        description,
        type,
    }: {
        name?: string,
        description?: string,
        type?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/HelloWorld/foo',
            query: {
                'Name': name,
                'Description': description,
                'Type': type,
            },
        });
    }

}
