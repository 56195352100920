/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SimpleJsonResponse } from '../models/SimpleJsonResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UnideskVersion1000CultureNeutralPublicKeyTokenNullService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns string Success
     * @throws ApiError
     */
    public getTest(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public getD({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/d/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns SimpleJsonResponse Success
     * @throws ApiError
     */
    public getApiEnumCacheReset(): CancelablePromise<SimpleJsonResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/enum/Cache/reset',
        });
    }

}
