/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SimpleJsonResponse } from '../models/SimpleJsonResponse';
import type { TeamDto } from '../models/TeamDto';
import type { TeamDtoPagedResponse } from '../models/TeamDtoPagedResponse';
import type { TeamFilter } from '../models/TeamFilter';
import type { TeamLookupDtoPagedResponse } from '../models/TeamLookupDtoPagedResponse';
import type { UserInTeamStatus } from '../models/UserInTeamStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TeamService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns TeamDtoPagedResponse Success
     * @throws ApiError
     */
    public find({
        requestBody,
    }: {
        requestBody?: TeamFilter,
    }): CancelablePromise<TeamDtoPagedResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Team/find',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TeamLookupDtoPagedResponse Success
     * @throws ApiError
     */
    public findSimple({
        requestBody,
    }: {
        requestBody?: TeamFilter,
    }): CancelablePromise<TeamLookupDtoPagedResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Team/find-simple',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TeamDto Success
     * @throws ApiError
     */
    public get({
        id,
    }: {
        id: string,
    }): CancelablePromise<TeamDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Team/get/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns TeamDto Success
     * @throws ApiError
     */
    public upsert({
        requestBody,
    }: {
        requestBody?: TeamDto,
    }): CancelablePromise<TeamDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Team/upsert',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns SimpleJsonResponse Success
     * @throws ApiError
     */
    public changeStatus({
        userId,
        teamId,
        status,
    }: {
        userId?: string,
        teamId?: string,
        status?: UserInTeamStatus,
    }): CancelablePromise<SimpleJsonResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Team/change-status',
            query: {
                'userId': userId,
                'teamId': teamId,
                'status': status,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns SimpleJsonResponse Success
     * @throws ApiError
     */
    public deleteOne({
        id,
    }: {
        id?: string,
    }): CancelablePromise<SimpleJsonResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Team/delete',
            query: {
                'id': id,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

}
