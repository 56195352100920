/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UserFunction {
    NONE = 'None',
    GUEST = 'Guest',
    AUTHOR = 'Author',
    TEACHER = 'Teacher',
    SUPERVISOR = 'Supervisor',
    OPPONENT = 'Opponent',
    EXTERNAL = 'External',
}
