/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserDto } from '../models/UserDto';
import type { UserFilter } from '../models/UserFilter';
import type { UserLookupDtoPagedResponse } from '../models/UserLookupDtoPagedResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns UserDto Success
     * @throws ApiError
     */
    public get({
        id,
    }: {
        id: string,
    }): CancelablePromise<UserDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Users/get/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns UserDto Success
     * @throws ApiError
     */
    public update({
        requestBody,
    }: {
        requestBody?: UserDto,
    }): CancelablePromise<UserDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Users/update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserLookupDtoPagedResponse Success
     * @throws ApiError
     */
    public find({
        requestBody,
    }: {
        requestBody?: UserFilter,
    }): CancelablePromise<UserLookupDtoPagedResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Users/find',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserDto Success
     * @throws ApiError
     */
    public getTheBestTeachers(): CancelablePromise<Array<UserDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Users/the-best-teachers',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public deleteOne({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Users/delete/{id}',
            path: {
                'id': id,
            },
        });
    }

}
