/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlobWithName } from '../models/BlobWithName';
import type { EvaluationDetailDto } from '../models/EvaluationDetailDto';
import type { EvaluationDto } from '../models/EvaluationDto';
import type { EvaluationPeekDto } from '../models/EvaluationPeekDto';
import type { IResult } from '../models/IResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EvaluationsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns EvaluationDto Success
     * @throws ApiError
     */
    public getAll({
        id,
    }: {
        id: string,
    }): CancelablePromise<Array<EvaluationDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/evaluation/list/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns EvaluationDetailDto Success
     * @throws ApiError
     */
    public getOne({
        id,
        pass,
    }: {
        id: string,
        pass?: string,
    }): CancelablePromise<EvaluationDetailDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/evaluation/get/{id}',
            path: {
                'id': id,
            },
            query: {
                'pass': pass,
            },
        });
    }

    /**
     * @returns EvaluationPeekDto Success
     * @throws ApiError
     */
    public peek({
        id,
    }: {
        id: string,
    }): CancelablePromise<EvaluationPeekDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/evaluation/peek/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public reject({
        id,
        pass,
        reason,
    }: {
        id: string,
        pass: string,
        reason?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/evaluation/reject/{id}',
            path: {
                'id': id,
            },
            query: {
                'pass': pass,
                'reason': reason,
            },
        });
    }

    /**
     * @returns EvaluationPeekDto Success
     * @throws ApiError
     */
    public upsert({
        requestBody,
    }: {
        requestBody: EvaluationDto,
    }): CancelablePromise<EvaluationPeekDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/evaluation/upsert',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns EvaluationDetailDto Success
     * @throws ApiError
     */
    public updateOne({
        pass,
        requestBody,
    }: {
        pass: string,
        requestBody: EvaluationDetailDto,
    }): CancelablePromise<EvaluationDetailDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/evaluation/update-one',
            query: {
                'pass': pass,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns EvaluationDto Success
     * @throws ApiError
     */
    public changeStatus({
        id,
        status,
    }: {
        id: string,
        status: string,
    }): CancelablePromise<EvaluationDto> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/evaluation/change-status',
            query: {
                'id': id,
                'status': status,
            },
        });
    }

    /**
     * @returns EvaluationDto Success
     * @throws ApiError
     */
    public changeStatusWithPass({
        id,
        status,
        pass,
    }: {
        id: string,
        status: string,
        pass?: string,
    }): CancelablePromise<EvaluationDto> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/evaluation/change-status-pass',
            query: {
                'id': id,
                'status': status,
                'pass': pass,
            },
        });
    }

    /**
     * @returns IResult Success
     * @throws ApiError
     */
    public getPdfPreview({
        id,
        pass,
    }: {
        id: string,
        pass?: string,
    }): CancelablePromise<IResult> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/evaluation/pdf-preview',
            query: {
                'id': id,
                'pass': pass,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public deleteOne({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/evaluation/delete/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public uploadAuthorFileInternship({
        evaluationId,
        internshipId,
        formData,
    }: {
        evaluationId: string,
        internshipId: string,
        formData?: {
            file: Blob;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/evaluation/upload-author-file-internship',
            query: {
                'evaluationId': evaluationId,
                'internshipId': internshipId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns BlobWithName Success
     * @throws ApiError
     */
    public downloadFileInternship({
        id,
    }: {
        id: string,
    }): CancelablePromise<BlobWithName> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/evaluation/download/file-internship',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public removeFileInternship({
        id,
    }: {
        id: string,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/evaluation/remove/file-internship',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public uploadSupervisorFileInternship({
        evaluationId,
        internshipId,
        pass,
        formData,
    }: {
        evaluationId: string,
        internshipId: string,
        pass: string,
        formData?: {
            file: Blob;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/evaluation/upload-supervisor-file-internship',
            query: {
                'evaluationId': evaluationId,
                'internshipId': internshipId,
                'pass': pass,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public uploadSupervisorFileInternshipUsingGrant({
        evaluationId,
        internshipId,
        formData,
    }: {
        evaluationId: string,
        internshipId: string,
        formData?: {
            file: Blob;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/evaluation/upload-supervisor-file-internship-grant',
            query: {
                'evaluationId': evaluationId,
                'internshipId': internshipId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns BlobWithName Success
     * @throws ApiError
     */
    public downloadSupervisorFileInternship({
        id,
        pass,
    }: {
        id: string,
        pass: string,
    }): CancelablePromise<BlobWithName> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/evaluation/download/file-internship-supervisor',
            query: {
                'id': id,
                'pass': pass,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public removeFileInternshipSupervisor({
        id,
        pass,
    }: {
        id: string,
        pass: string,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/evaluation/remove/file-internship-supervisor',
            query: {
                'id': id,
                'pass': pass,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public inviteSupervisorToInternship({
        internshipId,
        evaluationId,
    }: {
        internshipId: string,
        evaluationId: string,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/evaluation/invite-supervisor',
            query: {
                'internshipId': internshipId,
                'evaluationId': evaluationId,
            },
        });
    }

}
