/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BulkEditInternshipsDto } from '../models/BulkEditInternshipsDto';
import type { InternshipDto } from '../models/InternshipDto';
import type { InternshipDtoPagedResponse } from '../models/InternshipDtoPagedResponse';
import type { InternshipFilter } from '../models/InternshipFilter';
import type { InternshipStatus } from '../models/InternshipStatus';
import type { SimpleJsonResponse } from '../models/SimpleJsonResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InternshipService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns InternshipDto Success
     * @throws ApiError
     */
    public getOne({
        id,
    }: {
        id?: string,
    }): CancelablePromise<InternshipDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Internship/get-one',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @returns InternshipDtoPagedResponse Success
     * @throws ApiError
     */
    public find({
        requestBody,
    }: {
        requestBody?: InternshipFilter,
    }): CancelablePromise<InternshipDtoPagedResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Internship/find',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns InternshipDto Success
     * @throws ApiError
     */
    public upsert({
        requestBody,
    }: {
        requestBody?: InternshipDto,
    }): CancelablePromise<InternshipDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Internship/upsert',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns SimpleJsonResponse Success
     * @throws ApiError
     */
    public deleteOne({
        id,
    }: {
        id?: string,
    }): CancelablePromise<SimpleJsonResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Internship/delete',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @returns InternshipDto Success
     * @throws ApiError
     */
    public changeStatus({
        id,
        status,
        note,
    }: {
        id?: string,
        status?: InternshipStatus,
        note?: string,
    }): CancelablePromise<InternshipDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Internship/change-status',
            query: {
                'id': id,
                'status': status,
                'note': note,
            },
        });
    }

    /**
     * @returns SimpleJsonResponse Success
     * @throws ApiError
     */
    public bulkEdit({
        requestBody,
    }: {
        requestBody?: BulkEditInternshipsDto,
    }): CancelablePromise<SimpleJsonResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Internship/bulk-edit',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
