/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AccountService } from './services/AccountService';
import { AdminService } from './services/AdminService';
import { ChangeTrackerService } from './services/ChangeTrackerService';
import { EmailService } from './services/EmailService';
import { EnumsService } from './services/EnumsService';
import { EvaluationsService } from './services/EvaluationsService';
import { HelloWorldService } from './services/HelloWorldService';
import { ImportService } from './services/ImportService';
import { InternshipService } from './services/InternshipService';
import { KeywordsService } from './services/KeywordsService';
import { SettingsService } from './services/SettingsService';
import { TeamService } from './services/TeamService';
import { ThesisService } from './services/ThesisService';
import { UnideskVersion1000CultureNeutralPublicKeyTokenNullService } from './services/UnideskVersion1000CultureNeutralPublicKeyTokenNullService';
import { UsersService } from './services/UsersService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ApiClient {

    public readonly account: AccountService;
    public readonly admin: AdminService;
    public readonly changeTracker: ChangeTrackerService;
    public readonly email: EmailService;
    public readonly enums: EnumsService;
    public readonly evaluations: EvaluationsService;
    public readonly helloWorld: HelloWorldService;
    public readonly import: ImportService;
    public readonly internship: InternshipService;
    public readonly keywords: KeywordsService;
    public readonly settings: SettingsService;
    public readonly team: TeamService;
    public readonly thesis: ThesisService;
    public readonly unideskVersion1000CultureNeutralPublicKeyTokenNull: UnideskVersion1000CultureNeutralPublicKeyTokenNullService;
    public readonly users: UsersService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.account = new AccountService(this.request);
        this.admin = new AdminService(this.request);
        this.changeTracker = new ChangeTrackerService(this.request);
        this.email = new EmailService(this.request);
        this.enums = new EnumsService(this.request);
        this.evaluations = new EvaluationsService(this.request);
        this.helloWorld = new HelloWorldService(this.request);
        this.import = new ImportService(this.request);
        this.internship = new InternshipService(this.request);
        this.keywords = new KeywordsService(this.request);
        this.settings = new SettingsService(this.request);
        this.team = new TeamService(this.request);
        this.thesis = new ThesisService(this.request);
        this.unideskVersion1000CultureNeutralPublicKeyTokenNull = new UnideskVersion1000CultureNeutralPublicKeyTokenNullService(this.request);
        this.users = new UsersService(this.request);
    }
}

