/* THIS FILE IS AUTO-GENERATED BY Unidesk.Client.ModelGenerator */
/* DO NOT EDIT */
/* GENERATED ON 2023-12-27 17:48:04 */

export const User_SuperAdmin = {"name":"User_SuperAdmin","description":"Super Admin","id":"4bffef59-a0f2-428d-921a-a4e55cef7ca0"};
export const User_Admin = {"name":"User_Admin","description":"Admin","id":"6d38d901-b11e-47f3-bc7e-b6d0f57d776d"};
export const User_Teacher = {"name":"User_Teacher","description":"Teacher","id":"908f82e0-f5b2-41ac-9c62-60d25b49c99b"};
export const User_Student = {"name":"User_Student","description":"Student","id":"2870eca9-ee10-4406-a3a0-ad23f8a03185"};
export const User_Guest = {"name":"User_Guest","description":"Guest","id":"07e6e5b4-8e31-411a-9ed9-26ec462984cc"};
export const Action_Merge_Keywords = {"name":"Action_Merge_Keywords","description":"Merge Keywords","id":"10018290-58ef-4bc9-b5b8-3d93dcb07805"};
export const Action_Import_From_Stag = {"name":"Action_Import_From_Stag","description":"Import From Stag","id":"2e4c247e-1f1d-4b3b-8960-222894f65c9d"};
export const Entity_Thesis_Edit = {"name":"Entity_Thesis_Edit","description":"Edit Thesis","id":"4c7b8395-9ee2-4e96-9c95-6120ffcb0092"};
export const Entity_Team_Edit = {"name":"Entity_Team_Edit","description":"Edit Team","id":"9950097c-8fc5-4414-9b99-8f26a6c4f29f"};
export const Validation_Ignore_Warnings = {"name":"Validation_Ignore_Warnings","description":"Ignore Warnings","id":"5472c129-8fda-49cb-81b6-1c68243640dd"};
export const Action_ThesisEvaluation_Manage = {"name":"Action_ThesisEvaluation_Manage","description":"Manage Thesis Evaluation","id":"93d3e148-f14e-478b-9ae6-a2139532dfe3"};
export const Action_ManageRolesAndGrants = {"name":"Action_ManageRolesAndGrants","description":"Manage Roles And Grants","id":"fe04208c-f591-41bc-bc07-1dd161cc0754"};
export const Action_Create_Team = {"name":"Action_Create_Team","description":"Create Team","id":"98449df2-4df6-4b38-a751-a08db3bb8300"};
export const Internship_View = {"name":"Internship_View","description":"Create Thesis","id":"52dd62d9-aa55-4e25-ad31-0ca8ed0e60dc"};
export const Internship_Manage = {"name":"Internship_Manage","description":"Manage Internship","id":"c3896cb6-ee0e-4aa9-ae58-3237a9b1fb3b"};
export const Action_Block_User = {"name":"Action_Block_User","description":"Block User","id":"12b4efcc-d49d-41b5-a611-79f827d2bc0e"};
export const Action_Unblock_User = {"name":"Action_Unblock_User","description":"Unblock User","id":"ab9e609c-995e-4347-866f-7b9491e37680"};
export const Email_View = {"name":"Action_Import_From_Stag","description":"Email View","id":"9c6dcce2-9754-4915-a111-c14f891592a7"};
export const Action_Create_User = {"name":"Action_Create_User","description":"Create User","id":"64cbbac6-0bf7-4f47-b6cd-d87af06ba77e"};
export const Manage_Faculties = {"name":"Manage_Faculties","description":"Manage Faculties","id":"b65f4933-75a4-400f-8a36-c1920fa81176"};
export const Manage_Departments = {"name":"Manage_Departments","description":"Manage Departments","id":"8140e778-c514-4194-9126-53e6d6c6db32"};
export const Manage_SchoolYears = {"name":"Manage_SchoolYears","description":"Manage SchoolYears","id":"b02ec44d-6c39-4b40-b3b5-720bd6e08d12"};
export const Manage_ThesisOutcomes = {"name":"Manage_ThesisOutcomes","description":"Manage ThesisOutcomes","id":"179b8b51-8563-4053-a494-7449033e5501"};
export const Manage_ThesisTypes = {"name":"Manage_ThesisTypes","description":"Manage ThesisTypes","id":"6b3cfb26-910d-4ff7-844f-054180fc7187"};
export const Manage_StudyProgrammes = {"name":"Manage_StudyProgrammes","description":"Manage StudyProgrammes","id":"a006bb72-e47c-4785-8b7e-b29c8a40367e"};
export const Manage_UserRoles = {"name":"Manage_UserRoles","description":"Manage UserRoles","id":"c6bcf738-53be-4cc7-b213-be9aa3dcb22a"};

export const All = [
    User_SuperAdmin,
    User_Admin,
    User_Teacher,
    User_Student,
    User_Guest,
    Action_Merge_Keywords,
    Action_Import_From_Stag,
    Entity_Thesis_Edit,
    Entity_Team_Edit,
    Validation_Ignore_Warnings,
    Action_ThesisEvaluation_Manage,
    Action_ManageRolesAndGrants,
    Action_Create_Team,
    Internship_View,
    Internship_Manage,
    Action_Block_User,
    Action_Unblock_User,
    Email_View,
    Action_Create_User,
    Manage_Faculties,
    Manage_Departments,
    Manage_SchoolYears,
    Manage_ThesisOutcomes,
    Manage_ThesisTypes,
    Manage_StudyProgrammes,
    Manage_UserRoles,

]
export const GrantsAll = All;

export const Grants = {
    User_SuperAdmin: User_SuperAdmin,
    User_Admin: User_Admin,
    User_Teacher: User_Teacher,
    User_Student: User_Student,
    User_Guest: User_Guest,
    Action_Merge_Keywords: Action_Merge_Keywords,
    Action_Import_From_Stag: Action_Import_From_Stag,
    Entity_Thesis_Edit: Entity_Thesis_Edit,
    Entity_Team_Edit: Entity_Team_Edit,
    Validation_Ignore_Warnings: Validation_Ignore_Warnings,
    Action_ThesisEvaluation_Manage: Action_ThesisEvaluation_Manage,
    Action_ManageRolesAndGrants: Action_ManageRolesAndGrants,
    Action_Create_Team: Action_Create_Team,
    Internship_View: Internship_View,
    Internship_Manage: Internship_Manage,
    Action_Block_User: Action_Block_User,
    Action_Unblock_User: Action_Unblock_User,
    Email_View: Email_View,
    Action_Create_User: Action_Create_User,
    Manage_Faculties: Manage_Faculties,
    Manage_Departments: Manage_Departments,
    Manage_SchoolYears: Manage_SchoolYears,
    Manage_ThesisOutcomes: Manage_ThesisOutcomes,
    Manage_ThesisTypes: Manage_ThesisTypes,
    Manage_StudyProgrammes: Manage_StudyProgrammes,
    Manage_UserRoles: Manage_UserRoles,
};
