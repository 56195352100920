/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImportOneRequest } from '../models/ImportOneRequest';
import type { ThesisLookupDto } from '../models/ThesisLookupDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ImportService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ThesisLookupDto Success
     * @throws ApiError
     */
    public importFromStag({
        year,
        department,
    }: {
        year?: number,
        department?: string,
    }): CancelablePromise<Array<ThesisLookupDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Import/stag-import-all',
            query: {
                'year': year,
                'department': department,
            },
        });
    }

    /**
     * @returns ThesisLookupDto Success
     * @throws ApiError
     */
    public importOneFromStag({
        requestBody,
    }: {
        requestBody?: ImportOneRequest,
    }): CancelablePromise<ThesisLookupDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Import/stag-import-one',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
