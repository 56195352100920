import { SVGAttributes } from "react";

export const TulLogo = (props: SVGAttributes<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 249.947 249.947">
    <g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path d="M125.02,50.833a2.647,2.647,0,0,0,2.636-2.635V2.6a2.636,2.636,0,0,0-5.271,0V48.2A2.69,2.69,0,0,0,125.02,50.833Z" />
          <path d="M105.83,53.347a2.646,2.646,0,0,0,1.863-3.228l-11.8-44.04A2.636,2.636,0,0,0,90.8,7.443l11.8,44.041A2.69,2.69,0,0,0,105.83,53.347Z" />
          <path d="M87.944,60.743a2.649,2.649,0,0,0,.965-3.6l-22.8-39.485a2.635,2.635,0,0,0-4.564,2.635l22.8,39.486A2.691,2.691,0,0,0,87.944,60.743Z" />
          <path d="M72.581,72.515a2.647,2.647,0,0,0,0-3.727l-32.24-32.24a2.636,2.636,0,0,0-3.727,3.727l32.24,32.24A2.69,2.69,0,0,0,72.581,72.515Z" />
          <path d="M60.79,87.863a2.649,2.649,0,0,0-.965-3.6l-39.486-22.8A2.636,2.636,0,0,0,17.7,66.03l39.485,22.8A2.692,2.692,0,0,0,60.79,87.863Z" />
          <path d="M53.372,105.739a2.648,2.648,0,0,0-1.864-3.228L7.467,90.711A2.635,2.635,0,0,0,6.1,95.8l44.041,11.8A2.691,2.691,0,0,0,53.372,105.739Z" />
          <path d="M50.833,124.926a2.647,2.647,0,0,0-2.635-2.635H2.6a2.636,2.636,0,0,0,0,5.271H48.2A2.69,2.69,0,0,0,50.833,124.926Z" />
          <path d="M53.347,144.117a2.648,2.648,0,0,0-3.228-1.864l-44.04,11.8a2.635,2.635,0,0,0,1.364,5.091l44.041-11.8A2.69,2.69,0,0,0,53.347,144.117Z" />
          <path d="M60.743,162a2.648,2.648,0,0,0-3.6-.965l-39.485,22.8a2.636,2.636,0,0,0,2.635,4.565l39.486-22.8A2.69,2.69,0,0,0,60.743,162Z" />
          <path d="M72.515,177.365a2.647,2.647,0,0,0-3.727,0l-32.24,32.24a2.636,2.636,0,0,0,3.727,3.727l32.24-32.24A2.69,2.69,0,0,0,72.515,177.365Z" />
          <path d="M87.863,189.157a2.648,2.648,0,0,0-3.6.965l-22.8,39.486a2.636,2.636,0,0,0,4.565,2.635l22.8-39.486A2.691,2.691,0,0,0,87.863,189.157Z" />
          <path d="M105.739,196.575a2.648,2.648,0,0,0-3.228,1.864l-11.8,44.04a2.636,2.636,0,0,0,5.091,1.365L107.6,199.8A2.691,2.691,0,0,0,105.739,196.575Z" />
          <path d="M124.926,199.113a2.648,2.648,0,0,0-2.635,2.636v45.594a2.636,2.636,0,0,0,5.271,0V201.749A2.691,2.691,0,0,0,124.926,199.113Z" />
          <path d="M144.117,196.6a2.648,2.648,0,0,0-1.864,3.228l11.8,44.041a2.635,2.635,0,0,0,5.091-1.364l-11.8-44.041A2.692,2.692,0,0,0,144.117,196.6Z" />
          <path d="M162,189.2a2.647,2.647,0,0,0-.965,3.6l22.8,39.486a2.636,2.636,0,0,0,4.565-2.636l-22.8-39.485A2.69,2.69,0,0,0,162,189.2Z" />
          <path d="M177.365,177.431a2.648,2.648,0,0,0,0,3.728l32.24,32.24a2.636,2.636,0,0,0,3.727-3.728l-32.24-32.24A2.691,2.691,0,0,0,177.365,177.431Z" />
          <path d="M189.157,162.084a2.647,2.647,0,0,0,.965,3.6l39.486,22.8a2.636,2.636,0,0,0,2.635-4.565l-39.486-22.8A2.69,2.69,0,0,0,189.157,162.084Z" />
          <path d="M196.575,144.207a2.648,2.648,0,0,0,1.864,3.228l44.04,11.8a2.636,2.636,0,0,0,1.365-5.091l-44.041-11.8A2.691,2.691,0,0,0,196.575,144.207Z" />
          <path d="M199.113,125.02a2.649,2.649,0,0,0,2.636,2.636h45.594a2.636,2.636,0,0,0,0-5.271H201.749A2.69,2.69,0,0,0,199.113,125.02Z" />
          <path d="M196.6,105.83a2.647,2.647,0,0,0,3.228,1.863l44.041-11.8A2.636,2.636,0,0,0,242.5,90.8l-44.041,11.8A2.691,2.691,0,0,0,196.6,105.83Z" />
          <path d="M189.2,87.944a2.648,2.648,0,0,0,3.6.965l39.486-22.8a2.635,2.635,0,0,0-2.636-4.564l-39.485,22.8A2.69,2.69,0,0,0,189.2,87.944Z" />
          <path d="M177.431,72.581a2.648,2.648,0,0,0,3.728,0l32.24-32.24a2.636,2.636,0,0,0-3.728-3.727l-32.24,32.24A2.691,2.691,0,0,0,177.431,72.581Z" />
          <path d="M162.084,60.79a2.648,2.648,0,0,0,3.6-.965l22.8-39.486a2.636,2.636,0,0,0-4.565-2.635l-22.8,39.485A2.691,2.691,0,0,0,162.084,60.79Z" />
          <path d="M144.207,53.372a2.648,2.648,0,0,0,3.228-1.864l11.8-44.041A2.635,2.635,0,0,0,154.145,6.1l-11.8,44.041A2.691,2.691,0,0,0,144.207,53.372Z" />
          <path d="M139.124,143.492a2.649,2.649,0,0,0-.486,3.7l27.756,36.172a2.636,2.636,0,0,0,4.182-3.209L142.82,143.979A2.692,2.692,0,0,0,139.124,143.492Z" />
          <path d="M143.435,139.2a2.647,2.647,0,0,0,.487,3.695l36.172,27.756a2.636,2.636,0,0,0,3.209-4.182l-36.172-27.756A2.691,2.691,0,0,0,143.435,139.2Z" />
          <path d="M146.488,133.936a2.647,2.647,0,0,0,1.426,3.443l42.124,17.448a2.635,2.635,0,0,0,2.017-4.869l-42.123-17.449A2.691,2.691,0,0,0,146.488,133.936Z" />
          <path d="M103.423,133.849a2.648,2.648,0,0,0-3.444-1.426L57.855,149.871a2.636,2.636,0,0,0,2.018,4.87L102,137.293A2.692,2.692,0,0,0,103.423,133.849Z" />
          <path d="M106.454,139.124a2.647,2.647,0,0,0-3.695-.486L66.586,166.394a2.636,2.636,0,0,0,3.209,4.182l36.173-27.756A2.692,2.692,0,0,0,106.454,139.124Z" />
          <path d="M110.748,143.435a2.648,2.648,0,0,0-3.7.487L79.3,180.094a2.636,2.636,0,0,0,4.182,3.209l27.756-36.172A2.691,2.691,0,0,0,110.748,143.435Z" />
          <path d="M116.011,146.488a2.649,2.649,0,0,0-3.444,1.426L95.119,190.038a2.636,2.636,0,0,0,4.87,2.017l17.448-42.123A2.69,2.69,0,0,0,116.011,146.488Z" />
          <path d="M121.885,148.075a2.647,2.647,0,0,0-2.957,2.269l-5.952,45.2a2.636,2.636,0,0,0,5.226.688l5.952-45.2A2.691,2.691,0,0,0,121.885,148.075Z" />
          <path d="M127.969,148.087a2.647,2.647,0,0,0-2.269,2.957l5.951,45.2a2.636,2.636,0,0,0,5.226-.688l-5.951-45.2A2.691,2.691,0,0,0,127.969,148.087Z" />
          <path d="M133.849,146.524a2.647,2.647,0,0,0-1.426,3.443l17.448,42.124a2.636,2.636,0,0,0,4.87-2.017L137.293,147.95A2.691,2.691,0,0,0,133.849,146.524Z" />
          <path d="M148.075,128.062a2.647,2.647,0,0,0,2.269,2.957l45.2,5.951a2.636,2.636,0,0,0,.688-5.226l-45.2-5.951A2.69,2.69,0,0,0,148.075,128.062Z" />
          <path d="M148.087,121.978a2.648,2.648,0,0,0,2.957,2.269l45.2-5.952a2.636,2.636,0,0,0-.688-5.226l-45.2,5.952A2.69,2.69,0,0,0,148.087,121.978Z" />
          <path d="M146.524,116.1a2.648,2.648,0,0,0,3.444,1.427l42.123-17.448a2.636,2.636,0,0,0-2.017-4.87L147.95,112.654A2.69,2.69,0,0,0,146.524,116.1Z" />
          <path d="M143.492,110.822a2.648,2.648,0,0,0,3.7.487L183.36,83.553a2.636,2.636,0,0,0-3.209-4.182l-36.172,27.756A2.691,2.691,0,0,0,143.492,110.822Z" />
          <path d="M139.2,106.511a2.647,2.647,0,0,0,3.695-.486L170.65,69.852a2.636,2.636,0,0,0-4.182-3.208l-27.756,36.172A2.69,2.69,0,0,0,139.2,106.511Z" />
          <path d="M133.936,103.458a2.646,2.646,0,0,0,3.443-1.426l17.448-42.124a2.635,2.635,0,0,0-4.869-2.017l-17.449,42.124A2.69,2.69,0,0,0,133.936,103.458Z" />
          <path d="M121.978,101.86a2.648,2.648,0,0,0,2.269-2.957L118.3,53.7a2.636,2.636,0,0,0-5.226.688l5.952,45.2A2.69,2.69,0,0,0,121.978,101.86Z" />
          <path d="M116.1,103.423a2.648,2.648,0,0,0,1.427-3.444L100.076,57.855a2.636,2.636,0,0,0-4.87,2.018L112.654,102A2.691,2.691,0,0,0,116.1,103.423Z" />
          <path d="M110.822,106.454a2.647,2.647,0,0,0,.487-3.695L83.553,66.586A2.636,2.636,0,0,0,79.371,69.8l27.756,36.173A2.691,2.691,0,0,0,110.822,106.454Z" />
          <path d="M106.511,110.748a2.648,2.648,0,0,0-.486-3.7L69.852,79.3a2.636,2.636,0,0,0-3.208,4.182l36.172,27.756A2.69,2.69,0,0,0,106.511,110.748Z" />
          <path d="M103.458,116.011a2.648,2.648,0,0,0-1.426-3.444L59.908,95.119a2.636,2.636,0,0,0-2.017,4.87l42.124,17.448A2.689,2.689,0,0,0,103.458,116.011Z" />
          <path d="M101.872,121.885a2.647,2.647,0,0,0-2.269-2.957l-45.2-5.952a2.636,2.636,0,0,0-.688,5.226l45.2,5.952A2.691,2.691,0,0,0,101.872,121.885Z" />
          <path d="M101.86,127.969A2.647,2.647,0,0,0,98.9,125.7L53.7,131.651a2.636,2.636,0,0,0,.688,5.226l45.2-5.951A2.691,2.691,0,0,0,101.86,127.969Z" />
          <path d="M128.062,101.872a2.647,2.647,0,0,0,2.957-2.269l5.951-45.2a2.636,2.636,0,0,0-5.226-.688l-5.951,45.2A2.691,2.691,0,0,0,128.062,101.872Z" />
        </g>
      </g>
    </g>
  </svg>
);
