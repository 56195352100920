/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminActions } from '../models/AdminActions';
import type { UserDto } from '../models/UserDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any Success
     * @throws ApiError
     */
    public action({
        action,
    }: {
        action?: AdminActions,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Admin/action',
            query: {
                'action': action,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public switchUser({
        username,
    }: {
        username?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Admin/switch-user',
            query: {
                'username': username,
            },
        });
    }

    /**
     * @returns UserDto Success
     * @throws ApiError
     */
    public blockUser({
        userId,
    }: {
        userId?: string,
    }): CancelablePromise<UserDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Admin/block-user',
            query: {
                'userId': userId,
            },
        });
    }

    /**
     * @returns UserDto Success
     * @throws ApiError
     */
    public unblockUser({
        userId,
    }: {
        userId?: string,
    }): CancelablePromise<UserDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Admin/unblock-user',
            query: {
                'userId': userId,
            },
        });
    }

}
