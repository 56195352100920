/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BooleanToastResponse } from '../models/BooleanToastResponse';
import type { LoginRequest } from '../models/LoginRequest';
import type { RegisterRequest } from '../models/RegisterRequest';
import type { ResetPasswordRequest } from '../models/ResetPasswordRequest';
import type { UserDtoToastResponse } from '../models/UserDtoToastResponse';
import type { UserWhoamiDtoToastResponse } from '../models/UserWhoamiDtoToastResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AccountService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any Success
     * @throws ApiError
     */
    public lianeLogin(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/liane-login',
        });
    }

    /**
     * @returns UserWhoamiDtoToastResponse Success
     * @throws ApiError
     */
    public login({
        requestBody,
    }: {
        requestBody?: LoginRequest,
    }): CancelablePromise<UserWhoamiDtoToastResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Account/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns BooleanToastResponse Success
     * @throws ApiError
     */
    public register({
        requestBody,
    }: {
        requestBody?: RegisterRequest,
    }): CancelablePromise<BooleanToastResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Account/register',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserWhoamiDtoToastResponse Success
     * @throws ApiError
     */
    public resetPassword({
        requestBody,
    }: {
        requestBody?: ResetPasswordRequest,
    }): CancelablePromise<UserWhoamiDtoToastResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Account/reset-password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserWhoamiDtoToastResponse Success
     * @throws ApiError
     */
    public whoAmI(): CancelablePromise<UserWhoamiDtoToastResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Account/whoami',
        });
    }

    /**
     * @returns UserWhoamiDtoToastResponse Success
     * @throws ApiError
     */
    public loginSso({
        path,
    }: {
        path: string,
    }): CancelablePromise<UserWhoamiDtoToastResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Account/login.sso/{path}',
            path: {
                'path': path,
            },
        });
    }

    /**
     * @returns UserDtoToastResponse Success
     * @throws ApiError
     */
    public logout(): CancelablePromise<UserDtoToastResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Account/logout',
        });
    }

}
